//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
import Stat from '@/components/Stats/Stat'
export default {
    name: "AchievementsList",
    props: [
        'name',
        'user_id',
        'isOwner'
    ],
    data() {
        return {
            isEmpty: true,
            stats: [],
            addStatsDialog: false,
            stat_name: '',
            stat_value: ''
        }
    },
    components: {
        EmptyMessage,
        Stat
    },
    methods: {
        addStat() {
            this.$http({
                url: 'stats',
                method: 'POST',
                data: {
                    stat_name: this.stat_name,
                    stat_value: this.stat_value
                }
            }).then((response) => {
                if (response.data['status'] == 'stat_created') {
                    this.$q.notify({
                        message: 'A new stat has been added on your profile.'
                    });
                    this.stat_name = '';
                    this.stat_value = '';
                    this.loadStats();
                } else {
                    this.$q.notify({
                        message: 'There has been an error while trying to add new stat. Please try again later.',
                        color: 'error'
                    });
                    this.stat_name = '';
                    this.stat_value = '';
                }
            });
        },
        showAddStats() {
            this.addStatsDialog = !this.addStatsDialog;
        },
        loadStats() {
          this.$http({
            url: 'users/' + this.user_id + '/stats',
            method: 'GET'
          }).then((response) => {
            switch (response.data['status']) {
              case 'stats_retrieved':
                this.stats = response.data['body']['stats'];
                this.isEmpty = false;
              break;
              case 'stats_empty':
                this.stats = [];
                this.isEmpty = true;
              break;
              }
            });
          return;            
        }
    },
    mounted() {
        this.loadStats();
    }
}
