//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatsView from '@/components/Stats/StatsView'
export default {
    name: "ProfileDetails",
    props:['data','isPreview','isOwner'],
    data() {
      return {
        profile: this.data
      }
    },
    created() {
      this.profile.share_email_address = this.profile.share_email_address == 1 ? true : false;
      this.profile.share_mobile_number = this.profile.share_mobile_number == 1 ? true : false;
      this.profile.share_work_info = this.profile.share_work_info == 1 ? true : false;
      this.profile.share_hometown = this.profile.share_hometown == 1 ? true : false;
    },
    components: {
      StatsView
    }
}
