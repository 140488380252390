import { render, staticRenderFns } from "./ProfileDetails.vue?vue&type=template&id=c995d1ec&scoped=true&"
import script from "./ProfileDetails.vue?vue&type=script&lang=js&"
export * from "./ProfileDetails.vue?vue&type=script&lang=js&"
import style0 from "./ProfileDetails.vue?vue&type=style&index=0&id=c995d1ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c995d1ec",
  null
  
)

export default component.exports
import {QBtn,QSeparator,QList,QItem,QItemSection,QItemLabel,QBadge} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QSeparator,QList,QItem,QItemSection,QItemLabel,QBadge})
