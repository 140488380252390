//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Stat",
    props: [
        'stat',
        'isOwner'
    ],
    data() {
      return {
        stat_name: this.stat.stat_name,
        stat_value: this.stat.stat_value,
        id: this.stat.id,
        editStat: false,
        deleteStat: false,
        isStatValueValid: true,
        isStatNameValid: true
      }
    },
    methods: {
      delet() {
        this.$http({
          url: 'stats/' + this.id,
          method: 'DELETE'
        }).then((response) => {
          if (response.data['status'] == 'stat_deleted') {
            this.$q.notify({
              message: 'The stat has been successfully deleted.',
              color: 'positive'
            });
            this.$emit('dirty');
          } else {
            this.$q.notify({
              message: 'There has been an error while trying to delete. Please try again later.',
              color: 'negative'
            });
          }
        })
      },
      edit() {
        this.editStat = !this.editStat;
      },
      save() {
        if (this.stat_value.length > 5) {
          this.isStatValueValid = false;
          return;
        }
        if (this.stat_name.length > 10) {
          this.isStatNameValid = false;
          return;          
        }
        this.isStatValueValid = true;
        this.$http({
          url: 'stats/' + this.id,
          method: 'PUT',
          data: {
            stat_name: this.stat_name, 
            stat_value: this.stat_value
          }
        }).then((response) => {
          if (response.data['status'] == 'stat_updated') {
            this.$q.notify({
              message: 'The stat has been successfully updated.',
              color: 'positive'
            });
            this.edit();
            this.$emit('dirty');
            
          } else {
            this.$q.notify({
              message: 'There has been an error while trying to save. Please try again later.',
              color: 'negative'
            });
          }
        })
      }
    }
}
